//import { build$ } from "protractor/built/element";
(function(window) {
    window.__env = window.__env || {};

    let settings = {
      RemainingPerson : {
        workorderSelectionCode : 'MOD52PG195REQWOSTATUS',
        wcPolicySelectionCode : 'MOD52PG195REQLICCAP',
        licenseSelectionCode : 'MOD52PG195REQLICCAP',
        contractorSelectionCode : 'MOD152PG195CONAPVV01'
      },
      ERROR : {
        CONTRACTOR_NOT_APPROVED : 'MSG195_7',
        WORKORDER_REMAINING_ZERO : 'MSG195_9',
        WORKORDER_EXPIRED : 'MSG195_10',
      },
      CONTRACTOR_APPROVAL_STATUS : 'MOD152PG195CONAPVV01',
      CONTRACTOR_ACTIVE_STATUS : 'MOD52PG195REQWOSTATUS',
      CONTRACTOR_REQ_CONSTATUS : 'MOD52PG195REQCONSTATUS',
      CONTRATOR_TYPE_STRING : 'MOD152PG195PROFWORD',
      USER_PROFILE_APPROVED : 'MOD152PG195CONAPVV01',
      REMAINING_CHECK_CONDITION : 'MOD52PG195REQWOCAP',
      STATE_CAPACITY_EXCEED_STATUS : 'MOD52PG195REQLICCAP',
      LICENSE_SELECTION_STATUS_SHOW : 'MOD52PG195REQWOSTATUS',
      VERIFY_LICENSE : 'MOD152PG195LICAPVV01',
      PF_VERIFICATION_CHECK : 'MOD52PG195REQVERYN', 
      POLICEVERIFICATION : 'MOD152PG196PROBGC',
      POLICEVERIFICATION_DOC: 'MOD152PG197PROBGCDOC',
      BANK_DETAILS: 'MOD152PG200PROBANMND',
    }

    window.__env = { ...window.__env, ...settings }
}(this));